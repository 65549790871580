<template>
  <div>
    <md-card class="md-card-global-sales">
      <md-card-header class="md-card-header-icon md-card-header-blue">
        <div class="card-icon">
          <md-icon>assignment</md-icon>
        </div>
        <md-button
          class="md-primary float-right md-round md-sm"
          style="margin-top: -20px"
          :disabled="!tableHasContent"
          @click="onDownload"
        >
          <md-icon>get_app</md-icon>
          Download as XLS
        </md-button>
      </md-card-header>

      <md-card-content>
        <div class="md-layout">
          <div class="md-layout-item">
            <FormDate
              v-model="filters.date"
              label="Date"
              :disabled-dates="{
                weekdays: [1,3,4,5,6,7]
              }"
            />
          </div>
          <div class="md-layout-item">
            <LevelsSelect
              v-model="filters.course_level_id"
            />
          </div>

          <div class="md-layout-item md-size-100">
            <md-button
              class="md-success md-sm w-100"
              @click="onFilter"
            >
              Filter
            </md-button>
          </div>
        </div>

        <div class="md-layout">
          <div class="md-layout-item">
            <vue-table
              ref="vtable"
              :values="vTable.values"
              :headers="vTable.headers"
              :actions="vTable.actions"
              :options="{
                tableClass:'table'
              }"
              :search="false"
              @changed="getAssessments"
            />
          </div>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import VueTable from '@/components/Tables/VueTable.vue';
import { FormDate } from '@/components/Inputs';
import { LevelsSelect } from '@/components/Inputs/selects';
import moment from 'moment';
import downloadFile from '@/utils/downloadFile';
import dateHelper from '@/utils/dateHelper';

export default {
  components: {
    FormDate,
    LevelsSelect,
    VueTable,
  },
  data: () => ({
    vTable: {
      headers: [
        {
          title: 'programme.student.student_number',
          mask: 'S. Number',
          sortable: true,
          width: 10,
        },
        {
          title: 'assessment.course_level.name',
          mask: 'Level',
          sortable: true,
        },
        {
          title: 'programme.student.full_name',
          mask: 'Name',
        },
        {
          title: 'grade',
          mask: 'test g.',
          sortable: true,
          editable: true,
          editableType: 'number',
          width: 10,
        },
        {
          title: 'participative_grade',
          mask: 'participative g.',
          sortable: true,
          editable: true,
          editableType: 'number',
          width: 10,
        },
        {
          title: 'receptive_grade',
          mask: 'receptive g.',
          sortable: true,
          editable: true,
          editableType: 'number',
          width: 10,
        },
        {
          title: 'productive_grade',
          mask: 'productive g.',
          sortable: true,
          editable: true,
          editableType: 'number',
          width: 10,
        },
        {
          title: 'final_grade',
          mask: 'final g.',
          sortable: true,
          after: '%',
          width: 10,
        },
        {
          title: 'remark',
          sortable: true,
          editable: true,
          editableType: 'text',
        },
      ],
      actions: [],
      values: {},
    },
    filters: {
      date: null,
      course_level_id: null,
    },
  }),
  computed: {
    tableHasContent() {
      return this.vTable.values.data?.length > 0;
    },
  },
  mounted() {
    this.filters.date = dateHelper.getLastWeekday(1);
    this.$refs.vtable.init();
  },
  methods: {
    onFilter() {
      this.$refs.vtable.init();
    },
    getAssessments(params) {
      this.loading = true;
      this.request('assessments_report', 'get', {
        ...this.filters,
        ...params,
      }, ({ data }) => {
        this.vTable.values = data;
      }, () => {
        this.loading = false;
      });
    },
    onDownload() {
      this.loading = true;
      this.request('assessments_report', 'download', {
        ...this.filters,
        download: true,
      }, ({ data }) => {
        const filename = `attendance_report_${moment().format('YYYY_MM_DD')}.xlsx`;
        downloadFile(data, filename);
      }, () => {
        this.loading = false;
      });
    },
  },
};
</script>
